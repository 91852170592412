import React from "react";
import { Link } from "react-router-dom";

function NavigationBar() {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        <Link to="#" className="navbar-brand">
          <img src={process.env.REACT_APP_NIMBLE_ICON_URL} alt="" width="24" height="24" className="d-inline-block align-text-top" />
          AtAGlance
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav ms-auto">
            <div className="nav-item">
              <Link to="/dashboard/" className="nav-link active">
                Home
              </Link>
            </div>
            <div className="nav-item">
              <Link to="/sales/" className="nav-link active">
                Sales
              </Link>
            </div>
            <div className="nav-item">
              <Link to="/salesByOrder/" className="nav-link active">
                Tickets
              </Link>
            </div>
            <div className="nav-item">
              <Link to="/labor/" className="nav-link active">
                Labor
              </Link>
            </div>
            <div className="nav-item">
              <Link to="/discounts/" className="nav-link active">
                Discounts
              </Link>
            </div>
            <div className="nav-item">
              <Link to="/voids/" className="nav-link active">
                Voids
              </Link>
            </div>
            <div className="nav-item">
              <Link to="/reports/" className="nav-link active">
                Reports
              </Link>
            </div>
            <div className="nav-item">
              <Link to="/report-config/" className="nav-link active">
                Report Config
              </Link>
            </div>
            <div className="nav-item">
              <Link to="/logout/" className="nav-link active">
                Logout
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavigationBar;
