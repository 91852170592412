import React from "react";
import { Navigate } from "react-router-dom";

const Sales = (props) => {
  const [data, setData] = React.useState({
    data: [{}],
  });
  const dataObj = {
    store_id: props.store,
    command: "sales",
    business_date: props.date,
  };
  const [isLoading, setIsLoading] = React.useState(false);
  const loadingMessage = (
    <div className="container text-center">
      <div className="spinner-border spinner-border-lg" role="status">
        <span className="visually-hidden"></span>
      </div>
    </div>
  );
  const controller = new AbortController();
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(dataObj),
    signal: controller.signal,
  };
  React.useEffect(() => {
    setIsLoading(true);
    fetch(process.env.REACT_APP_ATAGLANCE_LAMBDA, requestOptions)
      .then((res) => res.json())
      .then((resJson) => {
        setData(resJson);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.date]);

  const tableRows = data.data.map((data, index) => {
    return (
      <tr key={index}>
        <td className="text-end">{data.hour > 12 ? `${data.hour - 12}:00 PM` : `${data.hour}:00 AM`}</td>
        <td className="text-end">${data.sales ? data.sales.toFixed(2) : null}</td>
      </tr>
    );
  });

  const renderTable = (
    <div className="d-flex">
      <table className="table">
        <thead>
          <tr>
            <th className="text-end">Hour</th>
            <th className="text-end">Net Sales</th>
          </tr>
        </thead>
        <tbody>
          {!tableRows ? (
            <tr>
              <td>0.00</td>
              <td>0.00</td>
            </tr>
          ) : (
            tableRows
          )}
        </tbody>
      </table>
    </div>
  );

  if (!props.auth) {
    return <Navigate replace to="/" />;
  }
  return isLoading ? loadingMessage : renderTable;
};

export default Sales;
