import React from "react";
import { Link, Navigate } from "react-router-dom";

export default function Dashboard(props) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState({
    data: {},
  });
  const dataObj = {
    store_id: props.store,
    command: "summary",
    business_date: props.date ? props.date : "",
  };
  const controller = new AbortController();
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(dataObj),
    signal: controller.signal,
  };

  const loadingMessage = (
    <div className="spinner-border spinner-border-sm" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  );
  React.useEffect(() => {
    setIsLoading(true);
    fetch(process.env.REACT_APP_ATAGLANCE_LAMBDA, requestOptions)
      .then((res) => res.json())
      .then((resJson) => {
        if (JSON.parse(window.localStorage.getItem("businessDate")) === "" || !JSON.parse(window.localStorage.getItem("businessDate"))) {
          props.setBusinessDate(resJson.data.business_date);
        }
        setIsLoading(false);
        return setData(resJson);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.date]);

  if (!props.auth) {
    return <Navigate replace to="/" />;
  }
  return (
    <div>
      <div className="table-responsive-sm">
        <table className="table">
          <tbody>
            <tr>
              <td>Total Net Sales</td>
              <td className="text-end">
                {isLoading ? loadingMessage : !data.data.total_net_sales ? 0.0 : `$${data.data.total_net_sales.toFixed(2)}`}
              </td>
              <td>
                {data.data.total_net_sales ? (
                  <Link to="/sales/">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-chevron-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </Link>
                ) : null}
              </td>
            </tr>
            <tr>
              <td>Total Labor Hours</td>
              <td className="text-end">
                {isLoading ? loadingMessage : !data.data.labor_totals ? 0.0 : `${data.data.labor_totals.toFixed(2)}`}
              </td>
              <td>
                {data.data.labor_totals ? (
                  <Link to="/labor/">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-chevron-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </Link>
                ) : null}
              </td>
            </tr>
            <tr>
              <td>Average Sale</td>
              <td className="text-end">{isLoading ? loadingMessage : !data.data.avg_sale ? 0.0 : `$${data.data.avg_sale.toFixed(2)}`}</td>
              <td></td>
            </tr>
            <tr>
              <td>Sales Per Labor Hour</td>
              <td className="text-end">{isLoading ? loadingMessage : !data.data.splh ? 0.0 : `$${data.data.splh.toFixed(2)}`}</td>
              <td></td>
            </tr>
            <tr>
              <td>Total Discounts</td>
              <td className="text-end">
                {isLoading ? loadingMessage : !data.data.discounts_total ? 0.0 : `$${data.data.discounts_total.toFixed(2)}`}
              </td>
              <td>
                {data.data.discounts_total ? (
                  <Link to="/discounts/">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-chevron-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </Link>
                ) : null}
              </td>
            </tr>
            <tr>
              <td>Total Voids</td>
              <td className="text-end">
                {isLoading ? loadingMessage : !data.data.voids_total ? 0.0 : `$${data.data.voids_total.toFixed(2)}`}
              </td>
              <td>
                {data.data.voids_total ? (
                  <Link to="/voids/">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-chevron-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                  </Link>
                ) : null}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  );
}
