import React, { useEffect } from "react";

const DateAndTime = (props) => {
  function padtoTwoDigits(num) {
    return num.toString().padStart(2, "0");
  }

  function dateObjToString(dateObject) {
    const fullYear = dateObject.getFullYear();
    const monthMM = padtoTwoDigits(dateObject.getMonth() + 1);
    const dayDD = padtoTwoDigits(dateObject.getDate());
    dateObject = `${fullYear}-${monthMM}-${dayDD}`;
    props.setBusinessDate(dateObject);
    return;
  }

  function incrementDate() {
    let curDateAsDateObj = new Date(`${props.date}T00:00:00`);
    curDateAsDateObj.setDate(curDateAsDateObj.getDate() + 1);
    dateObjToString(curDateAsDateObj);
    return;
  }

  function decrementDate() {
    let curDateAsDateObj = new Date(`${props.date}T00:00:00`);
    curDateAsDateObj.setDate(curDateAsDateObj.getDate() - 1);
    dateObjToString(curDateAsDateObj);
    return;
  }

  useEffect(() => {
    const dateControl = document.querySelector("#date-string");
    dateControl.value = props.date;
  });

  function changeHandler(e) {
    props.setBusinessDate(e.target.value);
  }

  return (
    <div className="bg-dark text-center text-light p-1">
      <table className="table table-borderless table-sm table-dark">
        <thead>
          <tr>
            <th scope="col">Business Date</th>
            <th scope="col">Last Refreshed</th>
          </tr>
        </thead>
        <tbody className="text-center">
          <tr>
            <td>
              <button className="counter--minus" onClick={decrementDate}>
                &lt;
              </button>
              <span>
                <input type="date" id="date-string" name="date-string" onChange={changeHandler} />
              </span>
              <button className="counter--plus" onClick={incrementDate}>
                &gt;
              </button>
            </td>
            <td>{props.time}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default DateAndTime;
