import React from "react";
import { Navigate } from "react-router-dom";

const Logout = (props) => {
  localStorage.clear();
  React.useEffect(() => {
    props.setAuth(false);
    props.setBusinessDate("");
    props.setStore("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Navigate replace to="/" />;
};

export default Logout;
