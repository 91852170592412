import React from "react";
import { Navigate } from "react-router-dom";

function Login(props) {
  const [errorMessages, setErrorMessages] = React.useState({});
  const [storeArray, setStoreArray] = React.useState([]);
  const renderErrorMessage = (name) => name === errorMessages.name && <div className="error">{errorMessages.message}</div>;
  const [multiStore, setMultiStore] = React.useState(false);
  const errors = {
    login: "Invalid login, please try again",
    multiStore: "Please choose a store to log into",
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let { uname, pword, storeId } = document.forms[0];
    if (!storeId) {
      storeId = "";
    }

    const dataObj = {
      username: uname.value,
      password: pword.value,
      store_id: storeId.value,
      app_id: process.env.REACT_APP_APP_ID,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(dataObj),
    };

    function checkResponseData(responseData) {
      if (responseData.statusCode === 200) {
        props.setAuth(true);
        props.setStore(responseData.store_id);
      } else if (responseData.statusCode === 409) {
        setStoreArray(responseData.data);
        setErrorMessages({ name: "login", message: errors.multiStore });
        setMultiStore(true);
      } else {
        setErrorMessages({ name: "login", message: errors.login });
      }
      return;
    }

    fetch(process.env.REACT_APP_NIMBLE_AUTH_URL, requestOptions)
      .then((res) => res.json())
      .then((resJson) => {
        checkResponseData(resJson);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  };

  const mappedStoreArray = storeArray.map((data) => (
    <div key={data.store_id}>
      <input className="form-check-input" type="radio" name="storeId" id={data.store_id} value={data.store_id} />
      <label className="form-check-label" htmlFor={data.store_id}>
        {data.store_name}
      </label>
    </div>
  ));

  const renderForm = (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <label>Username </label>
          <input type="text" name="uname" required />
        </div>
        <div className="input-container">
          <label>Password </label>
          <input type="password" name="pword" required />
        </div>
        {renderErrorMessage("login")}
        {multiStore ? <div className="input-container">{mappedStoreArray}</div> : null}
        <div className="button-container">
          <input type="submit" value="Login" />
        </div>
      </form>
    </div>
  );

  if (props.auth && props.store) {
    return <Navigate replace to="/dashboard/" />;
  }
  return (
    <div className="login-form">
      <div className="title">Sign In</div>
      {props.auth ? <Navigate replace to="/dashboard/" /> : renderForm}
    </div>
  );
}

export default Login;
