import React from "react";

const Footer = () => {
  return (
    <footer className="fixed-bottom bg-dark text-center text-light">
      A Nimble Technologies Project | &copy; {process.env.REACT_APP_COPYRIGHT_YEAR}
    </footer>
  );
};

export default Footer;
