import React from "react";
import { Navigate } from "react-router-dom";

const SalesByTicket = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [ticketIsLoading, setTicketIsLoading] = React.useState(false);
  const [data, setData] = React.useState({
    data: [{}],
  });
  const [ticketData, setTicketData] = React.useState({
    data: [{}],
    payment_info: [{}],
    ticket_info: { discounts: [] },
  });
  const [discountData, setDiscountData] = React.useState([{}]);
  const controller = new AbortController();

  const dataObj = {
    store_id: props.store,
    command: "getAll",
    start_date: props.businessDate,
    end_date: props.businessDate,
  };

  const getRequestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(dataObj),
    signal: controller.signal,
  };

  const loadingMessage = (
    <div className="container text-center">
      <div className="spinner-border spinner-border-lg" role="status">
        <span className="visually-hidden"></span>
      </div>
    </div>
  );

  React.useEffect(() => {
    setIsLoading(true);
    fetch(process.env.REACT_APP_TICKET_VIEWER_LAMBDA, getRequestOptions)
      .then((res) => res.json())
      .then((resJson) => {
        setData(resJson);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.businessDate]);

  const renderTicketBody = ticketData.data.map((data) => {
    return (
      <>
        <tr
          id={data.receipt_item_id}
          key={data.receipt_item_id}
          className={`${data.status === "deleted" ? "text-decoration-line-through text-danger" : ""}`}
        >
          <td>{data.quantity}</td>
          <td>{data.item_name}</td>
        </tr>
        {data.children
          ? data.children.map((child) => {
              return (
                <tr
                  id={child.receipt_item_id}
                  key={`${data.receipt_item_id}-${child.receipt_item_id}`}
                  className={`${child.status === "deleted" ? "text-decoration-line-through text-danger" : ""}`}
                >
                  <td></td>
                  <td>&nbsp;&nbsp;{child.mod_name ? child.mod_name : child.item_name}</td>
                </tr>
              );
            })
          : null}
      </>
    );
  });

  const renderTotals = (
    <tbody>
      <tr>
        <td className="fw-bold">Sub Total:</td>
        <td className="text-end">${ticketData.ticket_info.subtotal ? ticketData.ticket_info.subtotal.toFixed(2) : 0.0}</td>
      </tr>
      <tr>
        <td className="fw-bold">Tax:</td>
        <td className="text-end">${ticketData.ticket_info.total_tax ? ticketData.ticket_info.total_tax.toFixed(2) : 0.0}</td>
      </tr>
      <tr>
        <td className="fw-bold">Total:</td>
        <td className="text-end">${ticketData.ticket_info.total ? ticketData.ticket_info.total.toFixed(2) : 0.0}</td>
      </tr>
    </tbody>
  );

  let renderDiscounts;
  if (discountData) {
    renderDiscounts = discountData.map((data) => {
      return (
        <tr key={data.id}>
          <td>
            Disc: {data.name} (-{data.discount_type ? (data.discount_type.includes("dollar") ? `$` : null) : null}
            {data.discount_amount ? parseFloat(data.discount_amount).toFixed(2) : null}
            {data.discount_type ? (data.discount_type.includes("percent") ? `%` : null) : null})
          </td>
          <td></td>
        </tr>
      );
    });
  }

  const renderPaymentRows = ticketData.payment_info.map((data, index) => {
    return (
      <div className={`container ${data.status === "voided" ? "text-decoration-line-through text-danger" : null}`} key={index}>
        <div className="row">
          <div className="col-6 text-start">{data.payment_type}</div>
          <div className="col-6 text-end">${data.amount ? data.amount.toFixed(2) : "0.00"}</div>
        </div>
        {data.tip
          ? data.tip.map((data, index) => {
              return (
                <div className="row" key={index}>
                  <div className="col-6 text-start">Added Tip: </div>
                  <div className="col-6 text-end">{data.amount ? `$${data.amount.toFixed(2)}` : null}</div>
                </div>
              );
            })
          : null}
        {data.display_account ? (
          <div className="row">
            <div className="col-12 text-start">&nbsp;&nbsp;&nbsp;&nbsp;{data.display_account}</div>
          </div>
        ) : null}
        <div className="row">
          <div className="col-12 text-start small">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Accepted by {data.emp_name} {`${data.time ? `at ${data.time.slice(-8)} UTC` : null}`}
          </div>
        </div>
      </div>
    );
  });

  const renderTicketModal = (
    <div
      className="modal fade"
      id="ticketModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticTicketViewerModal"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticTicketViewerModal">
              Order Information
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            {ticketIsLoading ? (
              loadingMessage
            ) : (
              <div className="container">
                <div className="ticket-header text-center">
                  {props.storeObj.name ? props.storeObj.name : null}
                  <br />
                  {props.storeObj.city && props.storeObj.state ? `${props.storeObj.city}, ${props.storeObj.state}` : null} <br />
                  Server: {ticketData.ticket_info ? ticketData.ticket_info.emp_name : "emp_name"} <br />
                  <span className="fw-bold fs-1">Order #{ticketData.ticket_info ? ticketData.ticket_info.order_num : "###"} </span>
                  <br />
                  {ticketData.ticket_info
                    ? `${ticketData.ticket_info.service_type}: T-${ticketData.ticket_info.table_num}: G-${ticketData.ticket_info.guest_count}`
                    : "Service Type"}
                </div>
                <hr />
                <table className="table table-sm table-borderless">
                  <tbody>{renderTicketBody}</tbody>
                </table>
                <hr />
                <table className="table table-sm table-borderless">
                  {discountData ? <tbody>{renderDiscounts}</tbody> : null}
                  {renderTotals}
                </table>
                <hr />
                {ticketData.payment_info ? renderPaymentRows : null}
              </div>
            )}
          </div>
          <div className="modal-footer">
            <button className="btn btn-secondary" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  const getTicketInfo = (event) => {
    event.preventDefault();

    const getTicketDataObj = {
      command: "getOne",
      order_id: event.target.id,
      store_id: props.store,
      start_date: props.businessDate,
    };
    const ticketRequestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(getTicketDataObj),
      signal: controller.signal,
    };
    setTicketIsLoading(true);
    fetch(process.env.REACT_APP_TICKET_VIEWER_LAMBDA, ticketRequestOptions)
      .then((res) => res.json())
      .then((resJson) => {
        setTicketData(resJson);
        setDiscountData(resJson.discounts);
        setTicketIsLoading(false);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
    return () => {
      controller.abort();
    };
  };

  const tableRows = data.data.map((data, index) => {
    return (
      <tr id={data.order_id} key={index}>
        <td className="text-center">
          <button
            className="btn btn-link"
            type="button"
            id={data.order_id}
            name={data.order_num}
            onClick={getTicketInfo}
            data-bs-toggle="modal"
            data-bs-target="#ticketModal"
          >
            {data.order_num}
          </button>
        </td>
        <td className="text-start">{data.employee_name}</td>
        <td className="text-end">${data.total_due ? data.total_due.toFixed(2) : null}</td>
      </tr>
    );
  });

  const renderTable = (
    <div className="container p-0 m-0">
      <div className="p-0">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th className="text-center">Order#</th>
                <th className="text-start">Employee</th>
                <th className="text-end">Total</th>
              </tr>
            </thead>
            <tbody>{tableRows}</tbody>
          </table>
        </div>
      </div>
      {renderTicketModal}
    </div>
  );
  if (!props.auth) {
    return <Navigate replace to="/" />;
  }
  return <div className="container">{isLoading ? loadingMessage : renderTable}</div>;
};

export default SalesByTicket;
