import React from "react";

function Header(props) {
  const [data, setData] = React.useState({
    data: {},
  });

  const dataObj = {
    store_id: props.store,
    command: "getLocation",
  };

  const controller = new AbortController();

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(dataObj),
    signal: controller.signal,
  };

  React.useEffect(() => {
    fetch(process.env.REACT_APP_ATAGLANCE_LAMBDA, requestOptions)
      .then((res) => res.json())
      .then((resJson) => {
        setData(resJson);
        props.setStoreObj(resJson.data);
      });
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderHeader = (
    <div className="bg-dark text-center text-light p-1">
      <h2>{data.data.name ? data.data.name : null}</h2>
      <h4>{data.data.city && data.data.state ? data.data.city + ", " + data.data.state : null}</h4>
    </div>
  );

  return renderHeader;
}

export default Header;
