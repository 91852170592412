import "./App.css";
import Header from "./components/Header";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Sales from "./pages/Sales";
import Dashboard from "./pages/Dashboard";
import { Routes, Route, Navigate } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Discounts from "./pages/Discounts";
import DateAndTime from "./components/DateAndTime";
import Labor from "./pages/Labor";
import Voids from "./pages/Voids";
import React from "react";
import Reports from "./pages/Reports";
import ReportConfig from "./pages/ReportConfig";
import SalesByTicket from "./pages/SalesByTicket";

function App() {
  function getLocalStorageOrDefault(key, defaultValue) {
    const stored = localStorage.getItem(key);
    if (!stored || stored === "false" || stored === "undefined" || stored === "") {
      localStorage.setItem(key, defaultValue);
      return defaultValue;
    }
    return JSON.parse(stored);
  }

  const [isAuthenticated, setIsAuthenticated] = React.useState(getLocalStorageOrDefault("isAuthenticated", false));
  const [storeId, setStoreId] = React.useState(getLocalStorageOrDefault("storeId", ""));
  const [myDate, setMyDate] = React.useState(getLocalStorageOrDefault("businessDate", ""));
  const myTime = new Date().toLocaleTimeString();

  const [storeInfo, setStoreInfo] = React.useState({});

  React.useEffect(() => {
    window.localStorage.setItem("storeId", JSON.stringify(storeId));
  }, [storeId]);

  React.useEffect(() => {
    window.localStorage.setItem("isAuthenticated", JSON.stringify(isAuthenticated));
  }, [isAuthenticated]);

  React.useEffect(() => {
    window.localStorage.setItem("businessDate", JSON.stringify(myDate));
  }, [myDate]);

  return (
    <>
      <div className="App">
        <Navbar />
        {isAuthenticated ? (
          <>
            <Header store={storeId} storeObj={storeInfo} setStoreObj={setStoreInfo} />
            <DateAndTime date={myDate} time={myTime} setBusinessDate={setMyDate} />
          </>
        ) : null}
      </div>
      <Routes>
        <Route path="/" element={<Login auth={isAuthenticated} setAuth={setIsAuthenticated} store={storeId} setStore={setStoreId} />} />
        <Route
          path="/login"
          element={<Login auth={isAuthenticated} setAuth={setIsAuthenticated} store={storeId} setStore={setStoreId} />}
        />
        <Route path="/logout/" element={<Logout setAuth={setIsAuthenticated} setStore={setStoreId} setBusinessDate={setMyDate} />} />
        <Route path="/sales/" element={<Sales date={myDate} store={storeId} auth={isAuthenticated} />} />
        <Route
          path="/salesByOrder/"
          element={<SalesByTicket store={storeId} auth={isAuthenticated} businessDate={myDate} storeObj={storeInfo} />}
        />
        <Route
          path="/dashboard/"
          element={<Dashboard date={myDate} store={storeId} auth={isAuthenticated} setBusinessDate={setMyDate} />}
        />
        <Route path="/discounts/" element={<Discounts date={myDate} store={storeId} auth={isAuthenticated} />} />
        <Route path="/labor/" element={<Labor date={myDate} store={storeId} auth={isAuthenticated} />} />
        <Route path="/voids/" element={<Voids date={myDate} store={storeId} auth={isAuthenticated} />} />
        <Route path="/reports/" element={<Reports store={storeId} />} />
        <Route path="/report-config/" element={<ReportConfig store={storeId} auth={isAuthenticated} />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
