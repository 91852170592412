import React from "react";
import { Navigate } from "react-router-dom";

const ReportConfig = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [data, setData] = React.useState({
    data: [{}],
  });
  const dataObj = {
    store_id: props.store,
    command: "get",
  };

  const controller = new AbortController();
  const getRequestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(dataObj),
    signal: controller.signal,
  };

  const loadingMessage = (
    <div className="container text-center">
      <div className="spinner-border spinner-border-lg" role="status">
        <span className="visually-hidden"></span>
      </div>
    </div>
  );

  React.useEffect(() => {
    setIsLoading(true);
    fetch(process.env.REACT_APP_REPORT_SCHEDULE_LAMBDA, getRequestOptions)
      .then((res) => res.json())
      .then((resJson) => {
        setData(resJson);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelClick = (event) => {
    event.preventDefault();

    const delDataObj = {
      store_id: props.store,
      command: "delete",
      report_schedule_id: event.target.id,
    };

    const delRequestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(delDataObj),
      signal: controller.signal,
    };

    fetch(process.env.REACT_APP_REPORT_SCHEDULE_LAMBDA, delRequestOptions)
      .then((res) => res.json())
      .then((resJson) => {
        setData(resJson);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
    return () => {
      controller.abort();
    };
  };

  const handleAddClick = (event) => {
    event.preventDefault();
    let { email, sendDaily, sendWeekly, sendMonthly } = document.forms[0];

    email = email.value;
    sendDaily = sendDaily.checked ? "true" : "false";
    sendWeekly = sendWeekly.checked ? "true" : "false";
    sendMonthly = sendMonthly.checked ? "true" : "false";

    const addDataObj = {
      store_id: props.store,
      command: "add",
      recipient: email,
      daily: sendDaily,
      weekly: sendWeekly,
      monthly: sendMonthly,
      report_uuid: "d72d7edf-ad14-4fe2-abde-c5e4c3b096c0",
    };

    const addRequestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(addDataObj),
      signal: controller.signal,
    };

    fetch(process.env.REACT_APP_REPORT_SCHEDULE_LAMBDA, addRequestOptions)
      .then((res) => res.json())
      .then((resJson) => {
        console.log(resJson);
        setData(resJson);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
    return () => {
      controller.abort();
    };
  };

  const modalDiv = (
    <>
      <div className="d-flex justify-content-center py-5">
        <button type="button" className="btn btn-block btn-lg btn-success" data-bs-toggle="modal" data-bs-target="#addNew">
          Add new
        </button>
      </div>

      <div
        className="modal fade"
        id="addNew"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticAddNewLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticAddNewLabel">
                Add Report Schedule
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form className="form" id="addScheduleForm" onSubmit={handleAddClick}>
                <div className="input-container">
                  <label>Email</label>
                  <input type="email" name="email" required />
                </div>
                <div className="input-container">
                  <div className="form-check form-switch">
                    <input type="checkbox" className="form-check-input" role="switch" id="sendDaily" />
                    <label htmlFor="sendDaily" className="form-check-label">
                      Daily
                    </label>
                  </div>
                  <div className="form-check form-switch">
                    <input type="checkbox" className="form-check-input" role="switch" id="sendWeekly" />
                    <label htmlFor="sendWeekly" className="form-check-label">
                      Weekly
                    </label>
                  </div>
                  <div className="form-check form-switch">
                    <input type="checkbox" className="form-check-input" role="switch" id="sendMonthly" />
                    <label htmlFor="sendMonthly" className="form-check-label">
                      Monthly
                    </label>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button className="btn btn-secondary" data-bs-dismiss="modal">
                Close
              </button>
              <button type="submit" form="addScheduleForm" value="Submit" className="btn btn-primary" data-bs-dismiss="modal">
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const tableRows = data.data.map((data, index) => {
    return (
      <tr className="text-center" id={data.id} key={index}>
        <td className="text-start">{data.recipient_address}</td>
        <td className="text-start">{data.report_name}</td>
        {/* <td>{data.trigger_hour ? "\u2713" : null}</td> */}
        <td>{data.trigger_daily ? "\u2713" : null}</td>
        <td>{data.trigger_weekly ? "\u2713" : null}</td>
        <td>{data.trigger_monthly ? "\u2713" : null}</td>
        <td>
          {data.id ? (
            <button className="btn btn-sm btn-danger" type="button" id={data.id} onClick={handleDelClick}>
              Delete
            </button>
          ) : null}
        </td>
      </tr>
    );
  });

  const renderTable = (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th>Recipient</th>
            <th>Report</th>
            {/* <th>Hourly</th> */}
            <th>Daily</th>
            <th>Weekly</th>
            <th>Monthly</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </table>
      {/* <div className="d-flex justify-content-center py-5">
        <button type="button" className="btn btn-block btn-lg btn-success" data-bs-toggle="modal" data-bs-target="#addNew">
          Add new
        </button>
      </div> */}
      {modalDiv}
    </div>
  );
  if (!props.auth) {
    return <Navigate replace to="/" />;
  }
  return <div>{isLoading ? loadingMessage : renderTable}</div>;
};

export default ReportConfig;
